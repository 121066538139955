import { h, FunctionalComponent } from "preact";
import SimpleReactValidator from "simple-react-validator";
import { countWords } from "../../services/helpers";
import {
    useEffect,
    useImperativeHandle,
    useReducer,
    useRef,
    useState,
} from "preact/hooks";
import McChallengeFormData from "../../models/McChallengeFormData";

import styles from "./style.scss";
import { getQuestions } from "../../services/api";
import QuestionFormElement from "../QuestionFormElement";
import RequiredSymbol from "../RequiredSymbol";
import { forwardRef } from "react";
import Checkbox from "../Checkbox";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

interface ChallengeSubmitFormProps {
    onDataChange(type: string, params: any, valid: boolean): void;
    // organisationId: string;
    containerId: string;
    apiBasePath: string;
    apiKey: string;
    data: McChallengeFormData;
    isOpenChallenge?: boolean;
    showOpenChallenge?: boolean;
    challengeSpaceId?: string;
    no_extra_questions?: boolean;
}

interface ChallengeSubmitFormState {
    title: string;
    summary: string;
    description: string;
    team_count_preference: string | number;
    answers: any[];
    open_challenge_type?: string;
    additionalQuestionsValid: boolean;
}

const ChallengeSubmitForm: FunctionalComponent<ChallengeSubmitFormProps> = (
    {
        data,
        onDataChange,
        // organisationId,
        containerId,
        apiBasePath,
        challengeSpaceId,
        showOpenChallenge,
        no_extra_questions,
        apiKey,
    }: ChallengeSubmitFormProps,
    ref
) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const [formState, setFormState] = useState<ChallengeSubmitFormState>({
        title: "",
        summary: "",
        description: "",
        team_count_preference: "",
        open_challenge_type: showOpenChallenge ? "organisation" : "private",
        answers: [],
        additionalQuestionsValid: false,
    });
    const spaceId = challengeSpaceId
        ? challengeSpaceId
        : new URLSearchParams(window.location.search).get("spaceId");
    const [questions, setQuestions] = useState([]);

    const questionsFilter = questions.filter(
        (q: any) =>
            Boolean(q.attributes.options.required) ||
            Boolean(q.attributes.type === "text")
    );

    const additionalQuestionsValid = () =>
        questionsFilter //Filter only required questions
            .every((q: any) => {
                //Answer check
                const exists = formState.answers.find(
                    (a) => a.question_id === q.id
                )?.answer;
                if (typeof exists === "string") {
                    //Count words on text type
                    if (q.attributes.type === "text")
                        return (
                            countWords(exists) <= q.attributes.options.max_words
                        );
                    //Default check if not empty
                    return exists !== ""; // Make sure is not empty, even when checking id
                } else if (Array.isArray(exists)) {
                    //Check if multi choice questions have at least 1 answer
                    return exists.length > 0; // in case of multiple answers
                } else if (q.attributes.type === "text" && !exists) {
                    //Allow type=text questions that were not answered
                    return true;
                }

                return exists;
            });

    useEffect(() => {
        setFormState((p) => {
            return {
                ...p,
                additionalQuestionsValid: additionalQuestionsValid(),
            };
        });
    }, [formState.answers]);

    useEffect(() => {
        setFormState((p) => {
            return {
                ...p,
                additionalQuestionsValid: additionalQuestionsValid(),
            };
        });
    }, [questions, formState.answers]);

    const validatorConfig: any = {
        autoForceUpdate: { forceUpdate: forceUpdate },
        locale: "en",
        messages: {
            required: "This field is required",
        },
        validators: {
            maxwords: {
                // name the rule
                message: "A maximum of :values words is allowed",
                rule: (val, params) => {
                    return countWords(val) <= params;
                },
                // @ts-ignore
                messageReplace: (message, params) =>
                    message.replace(":values", params), // optional
            },
        },
        element: (message: string) => (
            <p className={styles.errorMessage}>{message}</p>
        ),
    };
    const validator = useRef(new SimpleReactValidator(validatorConfig));

    useImperativeHandle(ref, () => ({
        validator: validator.current,
    }));

    const handleInputChange = (event: any) => {
        const target = event.target;
        const value = target.type == "checkbox" ? target.checked : target.value;
        const name = target.name;

        setFormState({ ...formState, [name]: value });
    };

    const handleOpenChallengeChange = (event: any) => {
        setFormState({
            ...formState,
            ...{
                open_challenge_type: event.target.checked
                    ? "global"
                    : "organisation",
            },
        });
    };

    useEffect(() => {
        onDataChange(
            "challengeFormData",
            formState,
            validator.current.allValid()
        );
    }, [formState]);

    useEffect(() => {
        setFormState({ ...formState, ...data });

        if (no_extra_questions) {
            setQuestions([]);
        } else {
            //Fetch questions
            getQuestions(apiBasePath, apiKey, {
                filter: {
                    parent_id: spaceId,
                    context: "challenges",
                },
            })
                .then((result) => {
                    const res = result.data.data.sort(
                        (a, b) =>
                            a.attributes.list_position -
                            b.attributes.list_position
                    );
                    return setQuestions(res);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const onChange = (value: any, question: any) => {
        const answer: string | any[] = (() => {
            switch (question.attributes.type) {
                case "text":
                    return value;
                case "select":
                    return value.id;
                case "select.multi":
                    return value.map((a) => a.id);
            }
        })();

        setFormState((previousFormState) => {
            let temp = [...previousFormState.answers];

            const foundElement = temp.find(
                (a) => a.question_id === question.id
            );

            if (foundElement && !answer) {
                //DELETE current answer
                temp = temp.filter(
                    (a) => a.question_id !== foundElement.question_id
                );
            } else if (foundElement) {
                //UPDATE current answer
                foundElement.answer = answer;
            } else if (!foundElement) {
                //CREATE new answer
                temp.push({
                    question_id: question.id,
                    answer: answer,
                });
            }

            return {
                ...previousFormState,
                answers: temp,
            };
        });
    };

    return (
        <form className={styles.form} noValidate>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="title" className={styles.label}>
                    <RequiredSymbol /> Challenge title
                </label>
                <p className={styles.intro}>
                    Short one sentence description of the challenge
                </p>
                <input
                    className={styles.input}
                    name="title"
                    maxLength={5000}
                    value={formState.title}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("title")}
                    id="title"
                    type="text"
                    placeholder="Challenge title"
                />
                {validator.current.message(
                    "title",
                    formState.title,
                    "required|max:5000|min:3"
                )}
            </div>

            <div style={{ marginBottom: "1.5rem" }}>
                <label htmlFor="description" className={styles.label}>
                    <RequiredSymbol /> Challenge description
                </label>
                <p className={styles.intro}>
                    Provide some context about the challenge
                </p>
                <textarea
                    className={styles.input}
                    style={{ minHeight: "162px" }}
                    name="description"
                    rows={6}
                    value={formState.description}
                    onChange={handleInputChange}
                    onBlur={() =>
                        validator.current.showMessageFor("description")
                    }
                    id="description"
                    placeholder="Description"
                ></textarea>
                {validator.current.message(
                    "description",
                    formState.description,
                    "required|maxwords:5000|min:3"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="teamSizeLimit" className={styles.label}>
                    <RequiredSymbol /> Preferred amount of teams
                </label>
                <input
                    className={styles.input}
                    name="team_count_preference"
                    min="1"
                    value={formState.team_count_preference}
                    id="team_count_preference"
                    onChange={handleInputChange}
                    onBlur={() =>
                        validator.current.showMessageFor("preferred team count")
                    }
                    type="number"
                    placeholder="Enter a number"
                />
                {validator.current.message(
                    "preferred team count",
                    formState.team_count_preference,
                    "min:1,num|integer|required"
                )}
                <small className={styles.bottomText}>
                    How many teams would you preferably like to assign to this
                    challenge?
                    <br />
                    <strong>Note</strong>: availability depends on the amount of
                    teams that take part in this period.
                </small>
            </div>
            {showOpenChallenge == true && (
                <div style={{ marginBottom: "1rem" }}>
                    <Checkbox
                        checked={formState.open_challenge_type === "global"}
                        className={"a"}
                        name="is_global_open_challenge"
                        onChange={handleOpenChallengeChange}
                        id="is_global_open_challenge"
                        label={
                            <div
                                style={{
                                    display: "block",
                                    paddingBottom: "10px",
                                }}
                            >
                                <span
                                    htmlFor="isOpenChallenge"
                                    className={styles.label}
                                    style={{ display: "inline" }}
                                >
                                    Make my challenge also visible to lecturers
                                    from other educational institutions.
                                </span>
                                <Tippy
                                    theme="light"
                                    arrow={true}
                                    placement="top"
                                    content={
                                        <div style={{ padding: "0.5rem" }}>
                                            <p>
                                                Selecting this will give your
                                                open challenge a larger reach.
                                                More lecturers from different
                                                educational institutions can see
                                                this open challenge, and might
                                                reach out to you to discuss how
                                                your challenge might fit their
                                                courses.
                                            </p>
                                        </div>
                                    }
                                    allowHTML={true}
                                    className="cursor-pointer block"
                                >
                                    <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                            style={{
                                                fill: "rgba(217, 119, 6, 1)",
                                                marginLeft: "7px",
                                                width: "1rem",
                                            }}
                                        >
                                            <path d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm-48-160c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16h-32V240c0-8.8-7.2-16-16-16h-40c-8.8 0-16 7.2-16 16s7.2 16 16 16h24v96h-32zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
                                        </svg>
                                    </span>
                                </Tippy>
                            </div>
                        }
                        labelClassName={styles.checkboxLabel}
                    />
                </div>
            )}
            <div>
                {Boolean(questions.length) &&
                    questions?.map((question) => {
                        return (
                            <QuestionFormElement
                                parentStyles={styles}
                                key={"AnswersSelect" + question.id}
                                question={question}
                                value={
                                    formState.answers.find(
                                        (a) => a.question_id === question.id
                                    )?.answer
                                }
                                onChange={onChange}
                                validator={validator}
                                containerId={containerId}
                            />
                        );
                    })}
            </div>
        </form>
    );
};

export default forwardRef(ChallengeSubmitForm);
